import * as Sentry from "@sentry/nextjs"
import useSWR from "swr"
import { useRouter } from "next/router"

import type { MyPlan } from "../../pages/api/dashboard/users/myPlan"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useUserPlan() {
  const router = useRouter()
  const fetcher = async (url) => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    if (response && response.ok) {
      const plan = await response.json()
      if (plan) {
        // This shouldn't ever get here, since we are already gating it
        // with Clerk values in the layout wherever it's needed. However, this is an extra check
        // for if Clerk hasn't gotten our propegated values yet or if there's another
        // edge case where they don't have them. Also "null ID" is an edge case that means we probably
        // should not log in because we couldn't find the user.
        if (!plan?.user?.id || !plan?.user?.wealth_onboarded_at || plan?.user?.wealth_closed_at) {
          // Go to / instead of /dashboard since we might not know the user's status.
          router.push("/")
        }
        return plan
      }
    } else {
      if (response.status === 401) {
        router.push("/signin")
      } else {
        throw "User plan fetching failed"
      }
    }
  }

  const { data, error, mutate, isLoading } = useSWR<MyPlan>(() => `/api/dashboard/users/myPlan`, fetcher)
  const updatePlan = async (data: Partial<MyPlan>, tempLocalData?: MyPlan) => {
    mutate({ ...tempLocalData }, false)
    const response = await fetch(`/api/dashboard/users/myPlan`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    if (response) {
      if (response.ok) {
        await mutate()
        return
      } else {
        const res = await response.json()
        throw res
      }
    }
  }

  if (error) {
    Sentry.captureException(error)
  }

  return {
    myPlan: data,
    error,
    updatePlan,
    mutate,
    isLoading,
  }
}
