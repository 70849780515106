import clsx from "clsx"

import { formatCurrency } from "helpers/formatHelper"
import { useIsMobile } from "helpers/hooks/mediaQueryHook"
import styles from "./DoughnutChart.module.css"
import { LoadingWrapper, Space } from "../.."

type SingleColumn = {
  title: string
  color: string
  value: number
}

type SingleSplitColumn = SingleColumn & {
  subTitle: string
}

type SplitColumn = SingleColumn & {
  splits: SingleSplitColumn[]
}

export type Column = SingleColumn | SplitColumn

interface Props {
  columns: Column[]
  height?: number
  width?: number
  middleTitle?: any
  middleSubtitle?: any
  type?: "mini" | "full" //set to mini to hide the legend
  isLoading?: boolean
  removeWhitespace?: boolean
  legendValueStyle?: string
  legendMarkerStyle?: string
  outsidePlanTradingRestricted?: boolean
  className?: string
  strokeWidth?: number
}

export const DoughnutChart = ({
  columns,
  middleTitle,
  middleSubtitle,
  type = "full",
  isLoading,
  removeWhitespace = false,
  legendValueStyle,
  legendMarkerStyle,
  outsidePlanTradingRestricted,
  className,
  strokeWidth,
}: Props) => {
  const isMobile = useIsMobile()
  const total = columns.reduce((prev, curr) => prev + curr.value, 0)
  const startPositions = [0]
  const rotateAmount: { value: number; color: string }[] = []
  const additionalWhitespace = removeWhitespace ? 0 : columns.length * 5
  const allColumns = columns.map((col) => ((col as SplitColumn).splits ? (col as SplitColumn).splits : [col])).flat()
  for (let i = 0; i < allColumns.length; i++) {
    const lastStart = startPositions?.[startPositions.length - 1]
    const rotate = (allColumns[i].value / total) * (100 - additionalWhitespace)
    startPositions.push(lastStart + rotate + (removeWhitespace ? 0 : 5))
    rotateAmount.push({ value: rotate, color: total > 0 ? allColumns[i].color : "#F1F0F0" })
  }

  return (
    <div
      className={clsx(className, type === "mini" ? styles.mini : styles.full)}
      style={{ flexDirection: isMobile ? "column" : "row" }}
    >
      <div className={styles.graphContainer}>
        {middleTitle && (
          <div className={styles.middleContainer}>
            <div>
              <div>{middleTitle}</div>
              {middleSubtitle && <div className={styles.middleSubtitle}>{middleSubtitle}</div>}
            </div>
          </div>
        )}
        <svg viewBox="0 0 36 36" className={styles.circularChart}>
          {rotateAmount
            .map((rotate, idx) => (
              <path
                key={idx}
                className={`${styles.circle}  ${!isLoading ? styles.circleEnableAnimation : ""}`}
                strokeWidth={strokeWidth ? strokeWidth : type === "mini" ? 3 : 2}
                stroke={rotate.color}
                // strokeDasharray={rotateAmount.toString()}
                strokeDasharray={idx === 0 ? `${rotate.value} 100` : `0 ${startPositions[idx]} ${rotate.value} 100 `}
                d="M18 2.0845
a 15.9155 15.9155 0 0 1 0 31.831
a 15.9155 15.9155 0 0 1 0 -31.831"
              />
            ))
            .reverse()}
        </svg>
      </div>
      {type !== "mini" && (
        <div className={styles.legendContainer}>
          <Space gap={null} className={styles.legend}>
            {columns.map((column) => (
              <Space key={column.title} direction="column" justify="space-between" align="stretch">
                <Space direction="row" gap={12} justify="space-between" align="stretch">
                  <div className={styles.legendEntry}>
                    <div
                      className={clsx(styles.legendMarker, legendMarkerStyle)}
                      style={{ backgroundColor: column.color }}
                    ></div>
                    <span>{column.title}</span>
                  </div>
                  <LoadingWrapper
                    isLoading={isLoading}
                    className={clsx(styles.legendValue, legendValueStyle)}
                    noRenderWhileLoading
                    width={30}
                  >
                    <span data-cy={`${column.title}-legend-value`}>{formatCurrency(column.value)}</span>
                  </LoadingWrapper>
                </Space>
                {(column as SplitColumn).splits && (
                  <Space
                    key={column.title}
                    direction="column"
                    gap={8}
                    justify="space-between"
                    align="stretch"
                    className={styles.splitLegendWrapper}
                  >
                    {(column as SplitColumn).splits.map((splitColumn) => (
                      <Space key={splitColumn.title} direction="row" gap={12} justify="space-between" align="stretch">
                        <div className={styles.splitLegendEntry}>
                          <div
                            className={clsx(styles.legendMarker, legendMarkerStyle)}
                            style={{ backgroundColor: splitColumn.color }}
                            data-cy={`${splitColumn.subTitle}-legend-subtitle`}
                          >
                            {splitColumn.subTitle}
                          </div>
                          {!outsidePlanTradingRestricted && (
                            <span data-cy={`${splitColumn.title}-legend-entry`}>{splitColumn.title}</span>
                          )}
                        </div>
                        <LoadingWrapper
                          isLoading={isLoading}
                          className={clsx(styles.legendValue, legendValueStyle, styles.splitLegendValue)}
                          noRenderWhileLoading
                          width={30}
                        >
                          <span data-cy={`${splitColumn.title}-legend-value`}>
                            {formatCurrency(splitColumn.value, false)}
                          </span>
                        </LoadingWrapper>
                      </Space>
                    ))}
                  </Space>
                )}
              </Space>
            ))}
          </Space>
        </div>
      )}
    </div>
  )
}
