import dynamic from "next/dynamic"
import styles from "./CheckMarkAnimation.module.css"

import checkmarkAnimation from "../../../../public/animations/onboarding-checkmark-animation.json"
import loadingAnimation from "../../../../public/animations/onboarding-loading-animation.json"

const Lottie = dynamic(() => import("lottie-react"), { ssr: false })

interface Props {
  isLoading: boolean
  isSuccess?: boolean
}

export const CheckMarkAnimation = ({ isLoading, isSuccess }: Props) => {
  return (
    <>
      {isLoading && (
        <Lottie
          animationData={loadingAnimation}
          className={styles.checkMarkAnimationContainer}
          autoplay={true}
          loop={true}
        />
      )}

      {!isLoading && isSuccess && (
        <Lottie
          animationData={checkmarkAnimation}
          className={styles.checkMarkAnimationContainer}
          loop={false}
          autoplay={true}
        />
      )}
    </>
  )
}
