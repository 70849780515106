import React, { useState, forwardRef } from "react"
import DatePicker from "react-datepicker"
import styles from "./DateFilter.module.css"

import DateFilterIcon from "../../../../vector/icons/date-filter-icon.svg"

import "react-datepicker/dist/react-datepicker.css"

import OutsideClickHandler from "react-outside-click-handler"

interface Props {
  options: any[]
  handleFilterChange: (value) => void
}

export const DateFilter = ({ options, handleFilterChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(options[0])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const onOptionClicked = (option) => () => {
    setSelectedOption(option)

    if (option.name !== "dateRangeCustom") {
      setIsOpen(false)
      handleFilterChange(option)
    }
  }

  const onChange = ([startDate, endDate], option) => {
    setStartDate(startDate)
    setEndDate(endDate)

    if (endDate) {
      handleFilterChange({ ...option, value: startDate, secondValue: endDate })
    }
  }

  // @ts-ignore
  const DatepickerCustomInput = forwardRef(({ onClick }, ref: any) => (
    <div className={styles.optionLabel} onClick={onClick} ref={ref}>
      <span>Custom date</span>
    </div>
  ))

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsOpen(false)
      }}
    >
      <div className={`${styles.container}`}>
        <div className={`${styles.dropdownContainer}`}>
          <div className={`${styles.selectedOption}`} onClick={toggleOpen}>
            <DateFilterIcon /> {selectedOption.label}
          </div>
          {isOpen && (
            <div className={`${styles.dropdownItems}`}>
              <ul>
                {options.map((option) => (
                  <div
                    className={`${styles.dropdownItem} ${option.hasBorder ? styles.dropdownItemBordered : ""} ${
                      option.value === selectedOption.value ? styles.currentlySelected : ""
                    }`}
                    onClick={onOptionClicked(option)}
                    key={option.value}
                  >
                    {option.name !== "dateRangeCustom" && <span className={styles.optionLabel}>{option.label}</span>}
                    {option.name === "dateRangeCustom" && (
                      <DatePicker
                        className={styles.datePickerContainer}
                        wrapperClassName={styles.datePickerWrapper}
                        popperClassName={styles.datePickerPopper}
                        calendarClassName={styles.datePickerCalendar}
                        selected={startDate}
                        onChange={(update) => {
                          onChange(update, option)
                        }}
                        startDate={startDate}
                        endDate={endDate}
                        monthsShown={2}
                        selectsRange
                        customInput={<DatepickerCustomInput />}
                        showPopperArrow={false}
                        calendarStartDay={0}
                      />
                    )}
                  </div>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  )
}
