import styles from "./LegendBar.module.css"

interface Props {
  columns: {
    percentage: number
    color: string
    minWidth?: number
    dataCy?: string
  }[]
  noShares?: boolean
}

export const LegendBar = (props: Props) => {
  return (
    <div className={styles.legendBar}>
      {props.columns.map((column, index) => (
        <div
          key={index}
          className={styles.bar}
          style={{ width: `${column.percentage}%`, background: column.color, minWidth: column.minWidth }}
          data-cy={column.dataCy}
        ></div>
      ))}
      {props.noShares && <div className={styles.noShares} data-cy="legend-no-shares-bar"></div>}
    </div>
  )
}
