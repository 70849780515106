import { ReactNode, useEffect } from "react"
import clsx from "clsx"
import CloseIcon from "vector/icons/close-new.svg"
import { Button } from "components/core/atoms"
import styles from "./SideDrawer.module.css"

type Props = {
  isOpen: boolean
  onClose: () => void
  title: string
  content: ReactNode
  bottomCta?: {
    title: string
    onClick: () => void
    cancel?: boolean
  }
}

const SideDrawer = ({ title, content, isOpen, onClose, bottomCta }: Props) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose()
      }
    }
    window.addEventListener("keyup", handleKeyDown)
    return () => {
      window.removeEventListener("keyup", handleKeyDown)
    }
  }, [])
  return (
    <div className={clsx(styles.wrapper, isOpen && styles.visible)} onClick={onClose}>
      <div
        className={styles.container}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className={styles.title}>
          {title} <CloseIcon onClick={onClose} className={styles.closeIcon} />
        </div>
        {content}
        {bottomCta && (
          <div className={styles.bottomCtaWrapper}>
            <div className={styles.bottomCta}>
              {bottomCta.cancel && (
                <div className={styles.cancel} onClick={onClose}>
                  Cancel
                </div>
              )}
              <Button
                shape="square"
                type="primary"
                size="default"
                title={bottomCta.title}
                onClick={bottomCta.onClick}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SideDrawer
