/* eslint-disable security/detect-object-injection */
import React, { useState, useEffect, useCallback, ReactNode } from "react"
import clsx from "clsx"

import styles from "./StepWizard.module.css"

interface StepProps {
  children: JSX.Element | JSX.Element[] | React.ReactElement
  isActive: boolean
  transitions: string
}

export interface StepWizardProps {
  children: JSX.Element | JSX.Element[] | React.ReactElement
  className?: string
  initialStep?: number
  instance?: (instance: any) => void
  isLazyMount?: boolean
  nav?: ReactNode
  onStepChange?: (stats: { previousStep: number; activeStep: number }) => void
  withInvertedTransitions?: boolean
}

export type StepWizardChildProps<T extends Record<string, any> = {}> = {
  isActive: boolean
  currentStep: number
  totalSteps: number
  firstStep: () => void
  lastStep: () => void
  nextStep: () => void
  previousStep: () => void
  goToStep: (step: number) => void
  goToNamedStep: (step: string) => void
  hashKey?: string
  stepName?: string
  "data-step-name"?: string
} & T

interface State {
  activeStep: number
  classes: { [key: number]: string }
  namedSteps: { [key: string]: number | string }
}

const transitions = {
  enterRight: clsx(styles.animated, styles.fadeInRight),
  enterLeft: clsx(styles.animated, styles.fadeInLeft),
  exitRight: clsx(styles.animated, styles.fadeOutRight),
  exitLeft: clsx(styles.animated, styles.fadeOutLeft),
}

const invertedTransitions = {
  enterRight: clsx(styles.animated, styles.fadeInRightInverted),
  enterLeft: clsx(styles.animated, styles.fadeInLeftInverted),
  exitRight: clsx(styles.animated, styles.fadeOutRightInverted),
  exitLeft: clsx(styles.animated, styles.fadeOutLeftInverted),
}

const Step: React.FC<StepProps> = ({ children, isActive, transitions }) => (
  <div className={`${styles.step} ${transitions} ${isActive ? styles.active : ""}`.trim()}>{children}</div>
)

const StepWizard: React.FC<StepWizardProps> = React.memo(
  ({
    children = [],
    className = null,
    initialStep = 1,
    instance = () => {},
    isLazyMount = false,
    nav = null,
    onStepChange = () => {},
    withInvertedTransitions = false,
  }) => {
    const getSteps = () => React.Children.toArray(children)

    const firstStep = () => goToStep(1)

    const lastStep = () => goToStep(getSteps().length)

    const nextStep = () => setActiveStep(state.activeStep + 1)

    const previousStep = () => setActiveStep(state.activeStep - 1)

    const goToStep = (step: number | string) => {
      setActiveStep(Number(step) - 1)
    }

    const goToNamedStep = (step: string) => {
      if (typeof step === "string" && state.namedSteps[step] !== undefined) {
        setActiveStep(state.namedSteps[step] as number)
      } else {
        console.error(`Cannot find step with name "${step}"`)
      }
    }

    const isReactComponent = ({ type }: { type: any }) => typeof type === "function" || typeof type === "object"

    const initialState = useCallback((): State => {
      const state: State = {
        activeStep: 0,
        classes: {},
        namedSteps: {},
      }

      const steps = getSteps()
      steps.forEach((child: any, i) => {
        state.namedSteps[i] = (child.props && (child.props.stepName || child.props["data-step-name"])) || `step${i + 1}`
        state.namedSteps[state.namedSteps[i]] = i
      })

      const initialStepIndex = initialStep - 1
      if (initialStepIndex && steps[initialStepIndex]) {
        state.activeStep = initialStepIndex
      }

      return state
    }, [initialStep])

    const [state, setState] = useState<State>(initialState)

    useEffect(() => {
      instance({
        nextStep,
        previousStep,
        goToStep,
        goToNamedStep,
        firstStep,
        lastStep,
        currentStep: state.activeStep + 1,
        totalSteps: getSteps().length,
      })
    }, [state.activeStep])

    const getTransitions = () => (withInvertedTransitions ? invertedTransitions : transitions)

    const isInvalidStep = (next: number) => next < 0 || next >= getSteps().length

    const setActiveStep = (next: number) => {
      const active = state.activeStep
      if (active === next) return
      if (isInvalidStep(next)) {
        if (process.env.NODE_ENV !== "production") {
          console.error(`${next + 1} is an invalid step`)
        }
        return
      }

      const { classes } = state
      const transitions = getTransitions()

      if (active < next) {
        classes[active] = transitions.exitLeft
        classes[next] = transitions.enterRight
      } else {
        classes[active] = transitions.exitRight
        classes[next] = transitions.enterLeft
      }

      setState({
        ...state,
        activeStep: next,
        classes,
      })

      onStepChange({
        previousStep: active + 1,
        activeStep: next + 1,
      })
    }

    const props = {
      currentStep: state.activeStep + 1,
      totalSteps: getSteps().length,
      nextStep,
      previousStep,
      goToStep,
      goToNamedStep,
      firstStep,
      lastStep,
    } as any

    const { classes } = state
    const childrenWithProps = React.Children.map(getSteps(), (child: React.ReactElement, i) => {
      if (!child) return null

      props.isActive = i === state.activeStep
      props.transitions = classes[i]

      if (!isLazyMount || (isLazyMount && props.isActive)) {
        return <Step {...props}>{isReactComponent(child) ? React.cloneElement(child, props) : child}</Step>
      }

      return null
    })

    return (
      <div className={clsx(className, styles.container)}>
        {nav && React.cloneElement(nav as React.ReactElement, props)}
        <div className={styles.stepWrapper}>{childrenWithProps}</div>
      </div>
    )
  }
)

export default StepWizard
