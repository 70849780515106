import React, { useState } from "react"
import moment from "moment"
import type { ReactNode } from "react"
import styles from "./FilteredList.module.css"
import { Button } from "../.."

interface Props {
  isLoading: boolean
  items: any[] | null
  rowRenderer?: (row: any, index: number) => ReactNode
  rowContainer?: (constrainedItems) => ReactNode
  loadMoreButtonTitle?: string
  itemsPerPage?: number
  setFilter?: (value) => void
  filter: any
  showMoreButton?: boolean
}

export const FilteredList = ({
  isLoading,
  items,
  loadMoreButtonTitle,
  rowRenderer,
  itemsPerPage = 10,
  rowContainer,
  setFilter,
  filter,
  showMoreButton = true,
}: Props) => {
  // const [shownItemsCount, setShownItemsCount] = useState(items?.length > itemsPerPage ? itemsPerPage : items?.length)
  const [shownItemsCount, setShownItemsCount] = useState(itemsPerPage)

  const constrainedItems = items?.slice(0, shownItemsCount)

  function loadMore() {
    setShownItemsCount((prevValue) => (prevValue += itemsPerPage))

    if (items.length === constrainedItems.length) {
      setFilter({
        ...filter,
        from_date: {
          ...(filter.from_date || {}),
          value: moment(filter.from_date.value).subtract(1, "months").format("YYYY-MM-DD"),
        },
        _INTERNAL_STRUCT_: true,
      })
    }
  }

  if (isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.noResultsContainer}>
          <span className={styles.loadingContainer}>
            <div className={styles.loader} />
          </span>
        </div>
      </div>
    )
  }

  if (items && items.length === 0) {
    return (
      <div className={styles.container}>
        <div className={styles.noResultsContainer}>No results</div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.rowsContainer}>
        {rowContainer ? rowContainer(constrainedItems) : constrainedItems.map((row, index) => rowRenderer(row, index))}
      </div>
      {itemsPerPage != -1 && (
        <>
          {showMoreButton && constrainedItems.length >= itemsPerPage && constrainedItems.length !== items.length ? (
            <div className={`${styles.loadMoreButtonContainer}`}>
              <Button shape="round" size="large" type="primary" title={loadMoreButtonTitle} onClick={loadMore} />
            </div>
          ) : (
            <div className={styles.loadMoreButtonContainer}>
              <div className={styles.noMoreResultsContainer}>No more results for selected period. Load more?</div>
              <Button shape="round" size="large" type="primary" title={loadMoreButtonTitle} onClick={loadMore} />
            </div>
          )}
        </>
      )}
    </div>
  )
}
