import Script from "next/script"
import { useIsMobile } from "helpers/hooks/mediaQueryHook"

const FrontChatWidget = () => {
  const isMobile = useIsMobile()
  const initializeFrontChat = async () => {
    let response = null
    let result = null

    try {
      response = await fetch("/api/widgets/front", {
        method: "POST",
        headers: { Accept: "application/json" },
      })

      result = await response.json()
    } catch (e) {
      window?.Sentry?.captureException(e)
    }

    let initParams: any = {
      chatId: "ecc418bee62fb9a789c4a5180d8a3bd8",
      useDefaultLauncher: true,
    }

    if (result) {
      initParams = {
        ...initParams,
        ...result,
      }
    }

    try {
      window.FrontChat("init", initParams)

      if (isMobile) {
        //  auto close on mobile
        setTimeout(() => {
          const concernedElement = document.querySelector("#front-chat-holder > div > div > div > div > div > div")
          concernedElement?.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
            })
          )
        }, 8000)
      }
    } catch (error) {
      window?.Sentry?.captureException("Could not initialize Front chat")
    }
  }

  return (
    <Script
      src="https://chat-assets.frontapp.com/v1/chat.bundle.js"
      strategy="lazyOnload"
      onLoad={() => initializeFrontChat()}
    />
  )
}

export default FrontChatWidget
