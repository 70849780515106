import { useEffect, useState } from "react"
import type { ReactNode } from "react"
import { clsx } from "clsx"

import { SignedIn, SignedOut, RedirectToSignIn, UserButton, ClerkLoaded } from "@clerk/nextjs"

import { useIsMobile } from "helpers/hooks/mediaQueryHook"
import styles from "./TwoColumnLayout.module.css"
import Layout from "../../Layout"
import { Space } from "../.."
import FrontChatWidget from "../../../widgets/FrontChatWidget"

import ConfirmationIcon from "../../../../vector/icons/wealth-confirmation.svg"
import WarningIcon from "../../../../vector/icons/warning2.svg"
import ErrorIcon from "../../../../vector/icons/wealth-error.svg"

import DashboardLayoutNavigation from "../TwoColumnDashboardLayout/DashboardLayoutNavigation"

type Props = {
  title?: string | ReactNode
  subheadStatus?: {
    title: string
    status: "success" | "warning" | "error" | "loading"
    icon?: ReactNode
  }
  subhead?: string
  children?: undefined
  left: ReactNode
  right?: {
    content: ReactNode
    title: string
  }
  topBanner?: ReactNode
  enableLeftHeaderBg?: boolean
  hideNav?: boolean
  titleClassName?: string
  verificationNeeded?: boolean
}

export const TwoColumnLayout = (props: Props) => {
  const [isCypress, setIsCypress] = useState(undefined)

  useEffect(() => {
    setIsCypress(!!window.Cypress)
  }, [])

  if (isCypress === undefined) {
    return <></>
  }

  if (isCypress) {
    return (
      <Layout showHeader={false} showFooter={false}>
        <TwoColumnLayoutAuthenticated {...props} />
      </Layout>
    )
  }

  return (
    <>
      <SignedIn>
        <Layout showHeader={false} showFooter={false}>
          <TwoColumnLayoutAuthenticated {...props} />
        </Layout>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  )
}

const TwoColumnLayoutAuthenticated = ({
  title,
  subheadStatus,
  subhead,
  left,
  right,
  topBanner,
  enableLeftHeaderBg,
  hideNav,
  verificationNeeded = false,
  titleClassName,
}: Props) => {
  const isMobile = useIsMobile()

  return (
    <div className={styles.container}>
      {<FrontChatWidget />}
      <DashboardLayoutNavigation verificationNeeded={verificationNeeded} />
      <div className={styles.content}>
        <div
          className={`${right ? styles.leftContainer : styles.leftContainerSolo} ${
            hideNav ? styles.containerNoNav : ""
          }`}
        >
          {!isMobile && topBanner}
          {title && (
            <div
              className={`${styles.leftHeaderContainer} ${
                enableLeftHeaderBg
                  ? subheadStatus?.status === "error"
                    ? styles.leftHeaderContainerBgError
                    : styles.leftHeaderContainerBg
                  : ""
              } ${hideNav ? styles.hideNavContainer : ""}`}
            >
              <div className={enableLeftHeaderBg ? styles.leftHeaderContentAlt : styles.leftHeaderContent}>
                <h2
                  className={clsx(
                    styles.title,
                    enableLeftHeaderBg && styles.titleAlt,
                    hideNav && styles.hideNav,
                    titleClassName
                  )}
                  data-cy="page-title"
                >
                  {title}
                  {!right && (
                    <ClerkLoaded>
                      <div className="hideMobile">
                        <UserButton />
                      </div>
                    </ClerkLoaded>
                  )}
                </h2>
                {subheadStatus && subheadStatus.status !== "loading" && (
                  <Space direction="row" className={styles.subheadContainer} gap={null}>
                    <div>{getSubheadIconFromStatus(subheadStatus)}</div>
                    <div>
                      <div className={getSubheadStyleFromStatus(subheadStatus.status)}>{subheadStatus.title}</div>
                      {subhead && (
                        <div className={`${styles.subhead} ${enableLeftHeaderBg ? styles.subheadAlt : ""}`}>
                          {subhead}
                        </div>
                      )}
                    </div>
                  </Space>
                )}
              </div>
            </div>
          )}
          {isMobile && topBanner}
          <div className={enableLeftHeaderBg ? styles.leftContentAlt : styles.leftContent}>{left}</div>
        </div>
        {right && (
          <div className={styles.rightContainer}>
            <div className={styles.rightTitle}>
              <div>{right.title}</div>
              <ClerkLoaded>
                <div className="hideMobile">
                  <UserButton showName={true} />
                </div>
              </ClerkLoaded>
            </div>
            {right.content}
          </div>
        )}
      </div>
    </div>
  )
}

function getSubheadStyleFromStatus(status: Props["subheadStatus"]["status"]) {
  switch (status) {
    case "success":
      return `${styles.subheadStatus} ${styles.subheadStatusSuccess}`
    case "warning":
      return `${styles.subheadStatus} ${styles.subheadStatusWarning}`
    case "error":
      return `${styles.subheadStatus} ${styles.subheadStatusError}`
    case "loading":
      return `${styles.subheadStatus} ${styles.subheadStatusLoading}`
    default:
      return styles.subheadStatus
  }
}

function getSubheadIconFromStatus(subheadStatus: Props["subheadStatus"]) {
  if (subheadStatus.icon) {
    return subheadStatus.icon
  }
  switch (subheadStatus.status) {
    case "success":
      return <ConfirmationIcon className={styles.subheadIcon} />
    case "warning":
      return <WarningIcon className={styles.subheadIcon} />
    case "error":
      return <ErrorIcon className={styles.subheadIcon} />
    default:
      return null
  }
}
