import clsx from "clsx"
import styles from "./PageIndicator.module.css"

type Props = { totalPages: number; activePage: number }

const PageIndicator = ({ totalPages, activePage }: Props) => {
  return (
    <div className={styles.pageIndicatorsContainer}>
      {[...Array(totalPages).keys()].map((e) => (
        <div key={e} className={clsx(styles.pageIndicator, activePage === e && styles.active)} />
      ))}
    </div>
  )
}

export default PageIndicator
