import useSWR from "swr"
import axios from "axios"
import { IssuerInfoResponse } from "pages/api/issuer/types"
import { showErrorMessageAndLogToSentry } from "helpers/frontendError"

export function useGeneralIssuer() {
  const fetcher = async (url) => {
    const data = await axios.get(url).then((res) => res.data)
    return data?.data
  }

  const { data, error, mutate, isLoading, isValidating } = useSWR<IssuerInfoResponse>(`/api/issuer?limit=5`, fetcher)
  if (error) {
    showErrorMessageAndLogToSentry({ error: error.message })
  }
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  }
}
