import { useEffect, useState } from "react"
import type { ReactNode } from "react"
import Link from "next/link"
import { useRouter } from "next/router"

import Tooltip from "rc-tooltip"
import { CSSTransition } from "react-transition-group"
import clsx from "clsx"

import LogoSVG from "vector/sofia.svg"
import BurgerSVG from "../../../../vector/icons/burger.svg"
import HomeIcon from "../../../../vector/icons/nav-home.svg"
import UserIcon from "../../../../vector/icons/nav-user.svg"
import DocumentsIcon from "../../../../vector/icons/nav-statements.svg"
import HomeIconSelected from "../../../../vector/icons/nav-home-selected.svg"
import UserIconSelected from "../../../../vector/icons/nav-user-selected.svg"
import DocumentsIconSelected from "../../../../vector/icons/nav-statements-selected.svg"
import AboutUsIcon from "../../../../vector/icons/nav-about-us.svg"

import styles from "../TwoColumnDashboardLayout/DashboardLayoutNavigation.module.css"

type DirectoryItem = {
  url: string
  icon: ReactNode
  selectedIcon: ReactNode
  title: string
  disabled?: boolean
}

const DashboardAdminLayoutNavigation = () => {
  const router = useRouter()

  const directory: DirectoryItem[] = [
    {
      url: "/sofia",
      icon: <HomeIcon />,
      selectedIcon: <HomeIconSelected />,
      title: "Home",
      disabled: false,
    },
    {
      url: "/sofia/users",
      icon: <UserIcon />,
      selectedIcon: <UserIconSelected />,
      title: "Users",
      disabled: false,
    },
    {
      url: "/sofia/portfolios",
      icon: <DocumentsIcon />,
      selectedIcon: <DocumentsIconSelected />,
      title: "Portfolios",
      disabled: false,
    },
    {
      url: "/sofia/apex/connect",
      icon: <DocumentsIcon />,
      selectedIcon: <DocumentsIconSelected />,
      title: "Apex Connect",
      disabled: false,
    },
    {
      url: "/sofia/issuer/invitations",
      icon: <DocumentsIcon />,
      selectedIcon: <DocumentsIconSelected />,
      title: "Issuer Invitations",
      disabled: false,
    },
  ]

  const [burgerOpen, setBurgerOpen] = useState(false)
  const [scroll, setScroll] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY)
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const isActivePage = (path) => {
    const currentPath = router.asPath
    if (path === "/sofia") {
      // special case for home page
      return currentPath === path
    } else {
      return currentPath.startsWith(path)
    }
  }

  return (
    <>
      <div
        className={clsx(styles.mobileNav, burgerOpen && styles.mobileNavOpen)}
        onClick={() => {
          setBurgerOpen(false)
        }}
      >
        <div className={styles.navBurgerWrap} style={{ backgroundColor: `rgba(0,0,0,${scroll / 100})` }}>
          <Link href="/" passHref>
            <LogoSVG className={styles.mobileLogo} />
          </Link>
          <BurgerSVG
            className={styles.burger}
            onClick={(e) => {
              e.stopPropagation()
              setBurgerOpen(!burgerOpen)
            }}
          />
        </div>
      </div>

      <CSSTransition in={burgerOpen} timeout={350} classNames={"display"} unmountOnExit>
        <div
          className={styles.burgerNav}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {directory.map((dir) => (
            <Link
              href={dir.disabled ? "#" : dir.url}
              key={dir.url}
              className={`${styles.navItem} ${isActivePage(dir.url) ? styles.active : ""} 
          ${dir.disabled ? styles.disabled : ""}`}
            >
              {isActivePage(dir.url) ? dir.selectedIcon : dir.icon}
              <span>{dir.title}</span>
            </Link>
          ))}
        </div>
      </CSSTransition>

      <div className={styles.navBar}>
        <Link href="/" passHref className={styles.logo}>
          <LogoSVG className={styles.sofiaLogo} />
        </Link>
        {directory.map((dir) => (
          <Link href={dir.disabled ? "#" : dir.url} key={dir.url} passHref legacyBehavior>
            <Tooltip
              placement="right"
              overlayInnerStyle={{ maxWidth: "none" }}
              overlay={dir.title}
              trigger={"hover"}
              overlayClassName={styles.hideTooltip}
            >
              <a
                className={`${styles.navItem} ${isActivePage(dir.url) ? styles.active : ""} ${
                  dir.disabled ? styles.disabled : ""
                }`}
              >
                {isActivePage(dir.url) ? dir.selectedIcon : dir.icon}
                <span>{dir.title}</span>
              </a>
            </Tooltip>
          </Link>
        ))}
        <Tooltip
          placement="right"
          overlayInnerStyle={{ maxWidth: "none" }}
          overlay={"Email engineering for help"}
          trigger={"hover"}
        >
          <a className={styles.navItem} href="mailto:dev@candor.co">
            <AboutUsIcon />
            <span>Help</span>
          </a>
        </Tooltip>
      </div>
    </>
  )
}

export default DashboardAdminLayoutNavigation
