import { Button, Modal } from "components/core"
import styles from "./SetupConfirmModal.module.css"

export const SetupConfirmModal = ({ isOpen, onRequestClose, onConfirm, isLoading }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className={styles.modal} contentStyle={styles.modalContent}>
      <div className={styles.modalContainer}>
        <div className={styles.modalTitle}>Are you sure to officially start?</div>
        <div className={styles.modalDescription}>
          Employees will be able to start plans once you officially start. Make sure all policies are in place before
          proceeding.
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            shape="square"
            type="outline"
            size="default"
            className={styles.modalButton}
            title="Cancel"
            onClick={onRequestClose}
          />
          <Button
            shape="square"
            type="primary"
            size="default"
            className={styles.modalButton}
            title="Officially start"
            onClick={onConfirm}
            loading={isLoading}
          />
        </div>
      </div>
    </Modal>
  )
}
