import { ReactElement } from "react"
import clsx from "clsx"
import CaretIcon from "vector/icons/up.svg"
import styles from "./CollapsibleCard.module.css"

type Props = {
  title: string
  headerRight?: string
  body: ReactElement
  isOpen: boolean
  setIsOpen: () => void
  className?: string
}

const CollapsibleCard = ({ title, headerRight, body, isOpen, setIsOpen, className }: Props) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={clsx(styles.header, isOpen && styles.headerOpen)} onClick={setIsOpen}>
        <div className={styles.title}>
          <CaretIcon className={clsx(styles.caret, isOpen && styles.caretOpen)} />
          {title}
        </div>
        <div>{headerRight}</div>
      </div>
      <div className={clsx(styles.body, !isOpen && styles.bodyHidden)}>{body}</div>
    </div>
  )
}

export default CollapsibleCard
