import Script from "next/script"

import PropTypes from "prop-types"

import Head from "next/head"
import moment from "moment"

type Props = {
  description?: string
  meta?: any
  socialcard?: any
  title: string
  type?: any
  structuredData?: any
  disallowRobots?: boolean
  article?: any
}

function SEO({ description, meta = [], socialcard, title, type, structuredData, disallowRobots, article }: Props) {
  meta = [
    {
      name: `description`,
      content: description || "",
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description || "",
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:creator`,
      content: "@teamcandor",
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description || "",
    },
  ].concat(meta)

  if (socialcard) {
    if (!socialcard.startsWith("http")) {
      socialcard = `https://candor.co${socialcard}`
    }

    meta = meta.concat([
      {
        name: "twitter:card",
        content: "summary_large_image",
      },
      {
        name: "twitter:image",
        content: socialcard,
      },
      {
        property: "og:type",
        content: "article",
      },
      {
        prefix: "og: http://ogp.me/ns#",
        property: "og:image",
        content: socialcard,
      },
    ])
  } else {
    meta.push({
      name: "twitter:card",
      content: "summary",
    })
  }

  if (disallowRobots || process.env.IS_PREVIEW) {
    meta.push({
      name: "robots",
      content: "NOINDEX, NOFOLLOW",
    })
  }

  if (!structuredData && type === "article") {
    structuredData = {
      "@context": "http://schema.org",
      "@type": "NewsArticle",
      headline: title,
      description: description,
      image: [socialcard],
      publisher: {
        "@type": "Organization",
        name: "Candor",
        logo: {
          "@type": "ImageObject",
          url: "https://candor.co/assets/logo.png",
        },
      },
    }

    if (article) {
      structuredData.datePublished = moment(article.publishedAt).format("MMMM D, YYYY")
      structuredData.author = {
        "@type": "Person",
        name: article.author.name,
      }
      structuredData.keywords = article.category.title
    }
  }

  return (
    <>
      <Head>
        <title>{`${title} | Candor`}</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="theme-color" content="#000000" />
        {meta.map((attributes) => (
          // can't use splat operator for some unexplained reason
          <meta
            name={attributes.name}
            property={attributes.property}
            content={attributes.content}
            prefix={attributes.prefix}
            key={attributes.name || attributes.property}
          />
        ))}
        {structuredData && (
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
        )}
      </Head>
      {process.env.FOMO_SITE_ID && <Script src={`https://load.fomo.com/api/v1/${process.env.FOMO_SITE_ID}/load.js`} />}
    </>
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  socialcard: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  disallowRobots: PropTypes.bool,
}

export default SEO
