import React, { JSXElementConstructor } from "react"
import ReactModal from "react-modal"
import clsx from "clsx"
import styles from "./Modal.module.css"
import { Button } from "../.."

import CloseIcon from "../../../../vector/icons/close.svg"

interface Props extends ReactModal.Props {
  title?: string | React.ReactNode
  onRequestClose?: () => void
  CTA?: React.ComponentProps<typeof Button>
  altCTA?: { title: string; onClick: () => void }
  children: React.ReactChild | React.ReactChild[]
  illustration?: JSXElementConstructor<any> | string
  illustrationStyle?: any
  icon?: React.ReactNode
  titleStyle?: any
  hideCloseIcon?: boolean
  contentStyle?: any
  closeIconStyle?: any
  className?: any
  contentWidth?: number
  contentHeight?: number
  modalOverlayClassName?: any
}

export const Modal = ({
  title,
  onRequestClose,
  CTA,
  altCTA,
  illustration,
  illustrationStyle,
  icon,
  titleStyle,
  hideCloseIcon,
  contentStyle,
  className,
  closeIconStyle,
  contentWidth,
  contentHeight,
  modalOverlayClassName,
  ...props
}: Props) => {
  const ModalIcon = icon
  const Illustration = illustration
  return (
    <ReactModal
      {...props}
      onRequestClose={onRequestClose}
      bodyOpenClassName="hasoverlay"
      overlayClassName={{
        base: styles.modalOverlay,
        beforeClose: styles.modalOverlayBeforeClose,
        afterOpen: clsx(styles.modalOverlayAfterOpen, modalOverlayClassName),
      }}
      className={{
        base: `modal ${styles.modal} ${className}`,
        beforeClose: styles.modalBeforeClose,
        afterOpen: styles.modalAfterOpen,
      }}
      closeTimeoutMS={201}
    >
      {!hideCloseIcon && <CloseIcon className={closeIconStyle} onClick={onRequestClose} data-cy="modal-close-button" />}
      {title && (
        <div className={styles.titleContainer}>
          <span className={styles.title}>
            {!!ModalIcon && ModalIcon} <span className={titleStyle}>{title}</span>
          </span>
        </div>
      )}
      <div className={clsx(styles.content, contentStyle)} style={{ width: contentWidth, height: contentHeight }}>
        {Illustration &&
          (typeof Illustration === "string" ? (
            <img
              src={String(illustration)}
              alt="Illustration"
              className={clsx(styles.illustration, illustrationStyle)}
            />
          ) : (
            <Illustration className={clsx(styles.illustration, illustrationStyle)} />
          ))}
        {props.children}
        {CTA && (
          <div className={styles.ctaContainer}>
            {altCTA && (
              <span className={styles.altCTA} onClick={altCTA.onClick} data-cy={`${altCTA.title}-button`}>
                {altCTA.title}
              </span>
            )}
            {CTA && <Button {...CTA} shape="round" type="primary" size="slim" dataCy={`${CTA.title}-button`} />}
          </div>
        )}
      </div>
    </ReactModal>
  )
}
