import { useState } from "react"
import type { ReactNode } from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import clsx from "clsx"
import Tooltip from "rc-tooltip"

import { CSSTransition } from "react-transition-group"
import SECReportingIcon from "vector/nav-icons/nav-sec-reporting.svg"
import FilingsIconSelected from "vector/nav-icons/nav-sec-reporting-selected.svg"
import ReportingIconSelected from "vector/nav-icons/nav-reporting-selected.svg"
import { useFlags } from "helpers/flagHelper"
import BurgerSVG from "vector/icons/burger.svg"
import AboutUsIcon from "vector/icons/nav-about-us.svg"
import PoliciesIconHover from "vector/nav-icons/nav-policies-hover.svg"
import PoliciesIconSelected from "vector/nav-icons/nav-policies-selected.svg"
import PoliciesIcon from "vector/nav-icons/nav-policies.svg"
import InsightsIconHover from "vector/nav-icons/nav-insights-hover.svg"
import InsightsIconSelected from "vector/nav-icons/nav-insights-selected.svg"
import InsightsIcon from "vector/nav-icons/nav-insights.svg"
import FilingsIconHover from "vector/nav-icons/nav-filings-hover.svg"
import ReportingIconHover from "vector/nav-icons/nav-reporting-hover.svg"
import ReportingIcon from "vector/nav-icons/nav-reporting.svg"
import VestingIcon from "vector/nav-icons/nav-vesting.svg"
import VestingIconSelected from "vector/nav-icons/nav-vesting-selected.svg"
import VestingIconHover from "vector/nav-icons/nav-vesting-hover.svg"
import LogoSVG from "vector/logo-for-issuers.svg"
import CfoIcon from "vector/nav-icons/nav-cfo.svg"
import CfoIconSelected from "vector/nav-icons/nav-cfo-selected.svg"
import CfoIconHover from "vector/nav-icons/nav-cfo-hover.svg"
import BuybacksIconHover from "vector/nav-icons/nav-buybacks-hover.svg"
import BuybacksIconSelected from "vector/nav-icons/nav-buybacks-selected.svg"
import BuybacksIcon from "vector/nav-icons/nav-buybacks.svg"
import HomeIconSelected from "vector/nav-icons/nav-home-selected.svg"
import HomeIcon from "vector/nav-icons/nav-home.svg"
import HomeIconHover from "vector/nav-icons/nav-home-hover.svg"
import UserIconSelected from "vector/nav-icons/nav-clients-selected.svg"
import UserIconHover from "vector/nav-icons/nav-clients-hover.svg"
import UserIcon from "vector/nav-icons/nav-clients.svg"

import styles from "./PartnerLayoutNavigation.module.css"

type DirectoryItem = {
  url: string
  icon: ReactNode
  selectedIcon: ReactNode
  hoverIcon: ReactNode
  title: string
  disabled?: boolean
}

const IssuerLayoutNavigation = () => {
  const router = useRouter()
  const flags = useFlags()
  const directory: DirectoryItem[] = [
    {
      url: "/issuer",
      icon: <HomeIcon />,
      selectedIcon: <HomeIconSelected />,
      hoverIcon: <HomeIconHover />,
      title: "Home",
    },
    {
      url: "/issuer/users",
      icon: <UserIcon />,
      selectedIcon: <UserIconSelected />,
      hoverIcon: <UserIconHover />,
      title: "Users",
    },
    flags.showVesting
      ? {
          url: "/issuer/vesting",
          icon: <VestingIcon />,
          selectedIcon: <VestingIconSelected />,
          hoverIcon: <VestingIconHover />,
          title: "Vesting",
        }
      : null,
    {
      url: "/issuer/sec-reporting",
      icon: <SECReportingIcon />,
      selectedIcon: <FilingsIconSelected />,
      hoverIcon: <FilingsIconHover />,
      title: "SEC Reporting",
    },
    flags.showCfoToolbox
      ? {
          url: "/issuer/buybacks",
          icon: <BuybacksIcon />,
          selectedIcon: <BuybacksIconSelected />,
          hoverIcon: <BuybacksIconHover />,
          title: "Buybacks",
        }
      : null,
    {
      url: "/issuer/policies",
      icon: <PoliciesIcon />,
      selectedIcon: <PoliciesIconSelected />,
      hoverIcon: <PoliciesIconHover />,
      title: "Policies",
    },
    {
      url: "/issuer/custom-reports",
      icon: <ReportingIcon />,
      selectedIcon: <ReportingIconSelected />,
      hoverIcon: <ReportingIconHover />,
      title: "Custom reports",
    },
    flags.showCfoToolbox
      ? {
          url: "/issuer/cfo",
          icon: <CfoIcon />,
          selectedIcon: <CfoIconSelected />,
          hoverIcon: <CfoIconHover />,
          title: "CFO Toolbox",
        }
      : null,
    flags.showInsights
      ? {
          url: "/issuer/insights",
          icon: <InsightsIcon />,
          selectedIcon: <InsightsIconSelected />,
          hoverIcon: <InsightsIconHover />,
          title: "Insights",
        }
      : null,
  ]
  const [burgerOpen, setBurgerOpen] = useState(false)

  const isActivePage = (href: string) => {
    let activeUrl = router.asPath.split("?")[0].split("#")[0]

    if (activeUrl.split("/").length === 2) {
      return href === "/issuer"
    }
    if (activeUrl.split("/").length > 3) {
      // if the url is longer than 3 segments, it's a sub page
      activeUrl = activeUrl.split("/").slice(0, 3).join("/")
    }
    return href.includes(activeUrl)
  }

  return (
    <>
      <div
        className={clsx(styles.mobileNav, burgerOpen && styles.mobileNavOpen)}
        onClick={() => {
          setBurgerOpen(false)
        }}
      >
        <div className={styles.navBurgerWrap}>
          <Link href="/" passHref>
            <LogoSVG className={styles.mobileLogo} />
          </Link>
          <BurgerSVG
            className={styles.burger}
            onClick={(e) => {
              e.stopPropagation()
              setBurgerOpen(!burgerOpen)
            }}
          />
        </div>
      </div>

      <CSSTransition in={burgerOpen} timeout={350} classNames={"display"} unmountOnExit>
        <div
          className={styles.burgerNav}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {directory
            .filter((dir) => dir)
            .map((dir) => (
              <Link
                href={dir.disabled ? "#" : dir.url}
                key={dir.url}
                className={clsx(
                  styles.navItem,
                  isActivePage(dir.url) && styles.active,
                  dir.disabled && styles.disabled
                )}
              >
                {isActivePage(dir.url) ? dir.selectedIcon : dir.icon}
                <span>{dir.title}</span>
              </Link>
            ))}
        </div>
      </CSSTransition>

      <div className={styles.navBar}>
        <Link href="/" passHref className={styles.logo}>
          <LogoSVG />
        </Link>
        {directory
          .filter((dir) => dir)
          .map((dir) => (
            <Link href={dir.disabled ? "#" : dir.url} key={dir.url} passHref legacyBehavior>
              <Tooltip
                placement="right"
                overlayInnerStyle={{ maxWidth: "none" }}
                overlay={dir.title}
                trigger={"hover"}
                overlayClassName={styles.hideTooltip}
              >
                <a
                  className={clsx(
                    styles.navItem,
                    isActivePage(dir.url) && styles.active,
                    dir.disabled && styles.disabled
                  )}
                >
                  {isActivePage(dir.url) ? dir.selectedIcon : <div className={styles.icon}>{dir.icon}</div>}
                  {!isActivePage(dir.url) && <div className={styles.hoverIcon}>{dir.hoverIcon}</div>}
                  <span data-cy={`issuer-navigation-${dir.title.toLowerCase()}`}>{dir.title}</span>
                </a>
              </Tooltip>
            </Link>
          ))}
        <Tooltip placement="right" overlayInnerStyle={{ maxWidth: "none" }} overlay={"Email us!"} trigger={"hover"}>
          <a className={styles.navItem} href="mailto:issuers@candor.co">
            <AboutUsIcon />
            <span>Help</span>
          </a>
        </Tooltip>
      </div>
    </>
  )
}

export default IssuerLayoutNavigation
