import { ReactElement } from "react"
import clsx from "clsx"
import styles from "./TableNew.module.css"

type Props = {
  headers: string[]
  data: (string | number | ReactElement)[][]
  isLoading?: boolean
  tableClassName?: string
}

const Table = ({ headers, data, isLoading, tableClassName }: Props) => {
  return (
    <table className={clsx(styles.table, tableClassName)}>
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <div className={styles.loading}>Loading...</div>
        ) : (
          data?.map((row, rowIndex) => {
            return (
              <tr key={rowIndex}>
                {row.map((element, elementIndex) => (
                  <td key={elementIndex}>{element}</td>
                ))}
              </tr>
            )
          })
        )}
      </tbody>
    </table>
  )
}

export default Table
