import { useState } from "react"
import type { ReactNode } from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import clsx from "clsx"
import Tooltip from "rc-tooltip"
import { CSSTransition } from "react-transition-group"
import { useGeneralPartner } from "helpers/hooks/partners/partnersGeneralHook"

import BurgerSVG from "vector/icons/burger.svg"
import AboutUsIcon from "vector/icons/nav-about-us.svg"
import HomeIconHover from "vector/nav-icons/nav-home-hover.svg"
import HomeIconSelected from "vector/nav-icons/nav-home-selected.svg"
import HomeIcon from "vector/nav-icons/nav-home.svg"
import UserIconHover from "vector/nav-icons/nav-clients-hover.svg"
import UserIconSelected from "vector/nav-icons/nav-clients-selected.svg"
import UserIcon from "vector/nav-icons/nav-clients.svg"
import ReportingIconSelected from "vector/nav-icons/nav-reporting-selected.svg"
import ReportingIconHover from "vector/nav-icons/nav-reporting-hover.svg"
import ReportingIcon from "vector/nav-icons/nav-reporting.svg"
import LeadsIconHover from "vector/nav-icons/nav-leads-hover.svg"
import LeadsIconSelected from "vector/nav-icons/nav-leads-selected.svg"
import LeadsIcon from "vector/nav-icons/nav-leads.svg"
import TradingLogIconHover from "vector/nav-icons/nav-trading-log-hover.svg"
import TradingLogIconSelected from "vector/nav-icons/nav-trading-log-selected.svg"
import TradingLogIcon from "vector/nav-icons/nav-trading-log.svg"
import LogoSVG from "vector/logo.svg"
import styles from "./PartnerLayoutNavigation.module.css"

type DirectoryItem = {
  url: string
  icon: ReactNode
  selectedIcon: ReactNode
  hoverIcon: ReactNode
  title: string
  disabled?: boolean
}

const PartnerLayoutNavigation = () => {
  const router = useRouter()
  const { data: partnerData } = useGeneralPartner()
  const directory: DirectoryItem[] = [
    {
      url: "/partners",
      icon: <HomeIcon />,
      selectedIcon: <HomeIconSelected />,
      hoverIcon: <HomeIconHover />,
      title: "Home",
    },
    {
      url: "/partners/clients",
      icon: <UserIcon />,
      selectedIcon: <UserIconSelected />,
      hoverIcon: <UserIconHover />,
      title: "Clients",
    },
    {
      url: "/partners/leads",
      icon: <LeadsIcon />,
      selectedIcon: <LeadsIconSelected />,
      hoverIcon: <LeadsIconHover />,
      title: "Leads",
    },

    {
      url: "/partners/reporting",
      icon: <ReportingIcon />,
      selectedIcon: <ReportingIconSelected />,
      hoverIcon: <ReportingIconHover />,
      title: "Reporting",
    },
  ]

  if (partnerData?.requiresManualSelling) {
    directory.splice(directory.length - 1, 0, {
      url: "/partners/trading-log",
      icon: <TradingLogIcon />,
      selectedIcon: <TradingLogIconSelected />,
      hoverIcon: <TradingLogIconHover />,
      title: "Trading log",
    })
  }

  const [burgerOpen, setBurgerOpen] = useState(false)

  const isActivePage = (href) => {
    let activeUrl = router.asPath.split("?")[0].split("#")[0]

    if (activeUrl.split("/").length === 2) {
      return href === "/partners"
    }
    if (activeUrl.split("/").length > 3) {
      // if the url is longer than 3 segments, it's a sub page
      activeUrl = activeUrl.split("/").slice(0, 3).join("/")
    }
    if (href === "/partners/clients") {
      return href.includes(activeUrl) || "/partners/plans".includes(activeUrl)
    }
    return href.includes(activeUrl)
  }

  return (
    <>
      <div
        className={clsx(styles.mobileNav, burgerOpen && styles.mobileNavOpen)}
        onClick={() => {
          setBurgerOpen(false)
        }}
      >
        <div className={styles.navBurgerWrap}>
          <Link href="/" passHref>
            <LogoSVG className={styles.mobileLogo} />
          </Link>
          <BurgerSVG
            className={styles.burger}
            onClick={(e) => {
              e.stopPropagation()
              setBurgerOpen(!burgerOpen)
            }}
          />
        </div>
      </div>

      <CSSTransition in={burgerOpen} timeout={350} classNames={"display"} unmountOnExit>
        <div
          className={styles.burgerNav}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {directory.map((dir) => (
            <Link
              href={dir.disabled ? "#" : dir.url}
              key={dir.url}
              className={clsx(styles.navItem, isActivePage(dir.url) && styles.active, dir.disabled && styles.disabled)}
            >
              {isActivePage(dir.url) ? dir.selectedIcon : dir.icon}
              <span>{dir.title}</span>
            </Link>
          ))}
        </div>
      </CSSTransition>

      <div className={styles.navBar}>
        <Link href="/" passHref className={styles.logo}>
          <LogoSVG />
        </Link>
        {directory.map((dir) => (
          <Link href={dir.disabled ? "#" : dir.url} key={dir.url} passHref legacyBehavior>
            <Tooltip
              placement="right"
              overlayInnerStyle={{ maxWidth: "none" }}
              overlay={dir.title}
              trigger={"hover"}
              overlayClassName={styles.hideTooltip}
            >
              <a
                className={clsx(
                  styles.navItem,
                  isActivePage(dir.url) && styles.active,
                  dir.disabled && styles.disabled
                )}
                data-cy={`navigation-${dir.title}`}
              >
                {isActivePage(dir.url) ? dir.selectedIcon : <div className={styles.icon}>{dir.icon}</div>}
                {!isActivePage(dir.url) && <div className={styles.hoverIcon}>{dir.hoverIcon}</div>}
                <span>{dir.title}</span>
              </a>
            </Tooltip>
          </Link>
        ))}
        <Tooltip placement="right" overlayInnerStyle={{ maxWidth: "none" }} overlay={"Email us!"} trigger={"hover"}>
          <a className={styles.navItem} href="mailto:partners@candor.co">
            <AboutUsIcon />
            <span>Help</span>
          </a>
        </Tooltip>
      </div>
    </>
  )
}

export default PartnerLayoutNavigation
