import styles from "./TextFilter.module.css"

import TextFilterIcon from "../../../../vector/icons/text-filter-icon.svg"

interface Props {
  filter: any
  handleFilterChange: (value) => void
}

export const TextFilter = ({ filter, handleFilterChange }: Props) => {
  return (
    <div className={styles.container}>
      <input
        name={filter.type}
        onChange={(event) => {
          handleFilterChange({ name: "search", value: event?.target?.value })
        }}
        value={filter.value}
        placeholder={filter.label}
      />
      <TextFilterIcon />
    </div>
  )
}
