import clsx from "clsx"
import { ReactNode } from "react"
import styles from "./CustomTabPanel.module.css"

type Props = {
  tabs: {
    title: string
    content: ReactNode
  }[]
  activeTab: number
  changeTab: (newValue: number) => void
}

export const CustomTabPanel = ({ tabs, activeTab, changeTab }: Props) => {
  return (
    <div>
      <div className={styles.tabsContainer}>
        {tabs.map((tab, index) => (
          <span
            className={clsx(styles.tab, activeTab === index && styles.active)}
            onClick={() => changeTab(index)}
            key={tab.title}
          >
            {tab.title}
          </span>
        ))}
      </div>
      <div>{tabs.find((_, index) => index === activeTab).content}</div>
    </div>
  )
}
