import { useEffect, useState } from "react"
import type { ReactNode } from "react"

import { SignedIn, SignedOut, RedirectToSignIn, UserButton, ClerkLoaded } from "@clerk/nextjs"

import clsx from "clsx"
import Link from "next/link"
import CaretIcon from "vector/icons/right-caret.svg"
import FrontChatWidget from "components/widgets/FrontChatWidget"
import { SetupGuideWidget } from "components/issuer/SetupGuideWidget"
import styles from "./IssuerAndPartnerLayout.module.css"
import IssuerLayoutNavigation from "./IssuerLayoutNavigation"
import Layout from "../../Layout"

type Props = {
  title?: string
  linkBack?: string
  children?: undefined
  content: ReactNode
  wrapperClassName?: string
  headerClassName?: string
}

export const IssuerLayout = (props: Props) => {
  const [isCypress, setIsCypress] = useState(undefined)

  useEffect(() => {
    setIsCypress(!!window.Cypress)
  }, [])

  if (isCypress === undefined) {
    return <></>
  }

  return (
    <>
      <SignedIn>
        <Layout showHeader={false} showFooter={false} bodyClassName={"white"}>
          <SetupGuideWidget />
          <FrontChatWidget />
          <IssuerLayoutNavigation />
          <div className={clsx(styles.wrapper, props.wrapperClassName)}>
            <div className={clsx(styles.header, props.headerClassName)}>
              {props.linkBack ? (
                <Link className={styles.linkBack} href={props.linkBack}>
                  <CaretIcon /> Back
                </Link>
              ) : null}
              {props.title ? (
                <h3 className={styles.title} data-cy="page-title">
                  {props.title}
                </h3>
              ) : null}
              <ClerkLoaded>
                <div className={clsx(styles.userButton, "hideMobile")}>
                  <UserButton />
                </div>
              </ClerkLoaded>
            </div>

            <div className={styles.content}>{props.content}</div>
          </div>
        </Layout>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  )
}
