import React, { ReactNode, useEffect, Fragment, useState } from "react"
import moment from "moment"

import styles from "./ListFilters.module.css"
import { Chip } from "../../atoms/Chip/Chip"
import { DateFilter } from "../DateFilter/DateFilter"
import { TextFilter } from ".."
import { Input } from "../.."

interface Props {
  filter: any
  setFilter: (value) => void
  children?: ReactNode
  showDateFilter?: boolean
  dateFilterOptions?: any[]
  showTextFilter?: boolean
  className?: any
}

export const ListFilters = ({
  filter,
  setFilter,
  children,
  showDateFilter = false,
  dateFilterOptions = [],
  showTextFilter = false,
  className,
}: Props) => {
  const [activeFilter, setActiveFilter] = useState(null)

  useEffect(() => {
    setActiveFilter(filter?.show?.value)
  }, [])

  useEffect(() => {
    if (!filter._INTERNAL_STRUCT_) filter._INTERNAL_STRUCT_ = true
  }, [])

  const types = {
    "picker-single": (name, opts, handleFilterChange, activeFilterValue) => {
      return (
        <>
          {opts.options.map((option, index) => {
            return (
              <Chip
                key={index}
                label={option[1]}
                value={option[0]}
                activeValue={activeFilterValue}
                handleFilterChange={handleFilterChange}
                filterName={name}
              />
            )
          })}
        </>
      )
    },
  }

  const handleFilterChange = ({ name, value, secondValue }) => {
    if (name === "show") {
      setActiveFilter(value)
    }

    if (name === "dateRange") {
      setFilter({
        ...filter,
        from_date: {
          ...(filter.from_date || {}),
          value: moment().subtract(value, "months").format("YYYY-MM-DD"),
        },
        to_date: {
          ...(filter.to_date || {}),
          value: moment().format("YYYY-MM-DD"),
        },
        _INTERNAL_STRUCT_: true,
      })

      return
    }

    if (name === "dateRangeCustom") {
      setFilter({
        ...filter,
        from_date: {
          ...(filter.from_date || {}),
          value: moment(value).format("YYYY-MM-DD"),
        },
        to_date: {
          ...(filter.to_date || {}),
          value: moment(secondValue).format("YYYY-MM-DD"),
        },
        _INTERNAL_STRUCT_: true,
      })

      return
    }

    setFilter({
      ...filter,
      [name]: {
        ...(filter[name] || {}),
        value: value,
      },
      _INTERNAL_STRUCT_: true,
    })
  }

  const filtersList = []

  for (const name in filter) {
    const opts = filter[name]
    const fn = types[opts.type]
    if (!fn) continue
    filtersList.push(<Fragment key={name}>{fn(name, opts, handleFilterChange, activeFilter)}</Fragment>)
  }

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.headContainer} ${className}`}>
          <div className={`${styles.headContainerLeft}`}>{filtersList}</div>
          <div className={`${styles.headContainerRight}`}>
            {showDateFilter && (
              <div className={styles.dateContainer}>
                <DateFilter options={dateFilterOptions} handleFilterChange={handleFilterChange} />
              </div>
            )}
            {showTextFilter && <TextFilter filter={filter.search} handleFilterChange={handleFilterChange} />}
          </div>
        </div>
        <div className={styles.headerContainerMobile}>
          <Input
            type="select"
            options={dateFilterOptions
              .filter((filter) => filter.value !== "custom")
              .map((filter) => {
                return { key: filter.value, name: filter.label }
              })}
            width="100%"
            onChange={(e) => {
              handleFilterChange({ name: "dateRange", value: e.target.value, secondValue: undefined })
            }}
          />
        </div>
      </div>
      {children}
    </>
  )
}
