import clsx from "clsx"
import React, { ReactNode, useEffect } from "react"
import styles from "./MenuNav.module.css"

interface Props {
  tabs: { title: string; subtitle?: string; content: ReactNode }[]
  setActiveTab: (tab) => void
  activeTab: any //TODO add Tab type
  style?: "wide" | "collapsed" // wide is default
}

export const MenuNav = ({ tabs = [], setActiveTab, activeTab, style = "wide" }: Props) => {
  useEffect(() => {
    if (!!tabs.length && !activeTab) {
      setActiveTab(tabs[0])
    }
  }, [])

  return (
    <div className={clsx(styles.headerContainer, style === "collapsed" && styles.collapsed)}>
      {tabs.map((tab) => (
        <div
          key={tab.title}
          onClick={() => {
            setActiveTab(tab)
          }}
          className={clsx(
            styles.tabTitleContainer,
            tab.title === activeTab?.title && styles.active,
            style === "collapsed" && styles.collapsed
          )}
          data-cy={`tab-${tab.title}`}
        >
          <span className={clsx(styles.tabTitleText, style === "collapsed" && styles.collapsed)}>{tab.title} </span>
        </div>
      ))}
    </div>
  )
}
