import { ReactNode } from "react"
import styles from "./Table.module.css"

type Props = {
  headers: string[]
  data: (string | number | ReactNode)[][]
  isLoading?: boolean
}

const Table = ({ headers, data, isLoading }: Props) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          data?.map((e, i) => {
            return (
              <tr key={`${i}-${e[0]}`}>
                {e.map((f, idx) => (
                  <td key={`${idx}-${f}`}>{f}</td>
                ))}
              </tr>
            )
          })
        )}
      </tbody>
    </table>
  )
}

export default Table
