import React, { ReactNode, useState } from "react"
import styles from "./Card.module.css"
import RightArrowBox from "../../../../vector/icons/right-arrow-box.svg"
import ThreeDotsIcon from "../../../../vector/icons/three-dots.svg"
import ErrorIcon from "../../../../vector/icons/wealth-error-triangle.svg"
import { Space, Button, LoadingWrapper } from "../../atoms"

interface Props {
  title?: string | ReactNode
  content?: ReactNode
  actionTitle?: string
  CTA?: React.ComponentProps<typeof Button>
  accent?:
    | "primary"
    | "primaryWhite"
    | "primaryError"
    | "primaryWarning"
    | "disabled"
    | "greyActive"
    | "limeActive"
    | "orangeActive"
    | "darkGrey"
    | "lightGrey"
  topRightInfo?: string
  bottomRightInfo?: string
  additionalOptions?: {
    title: string
    icon: ReactNode
    onClick: () => void
  }[]
  enableRightArrowIcon?: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  size?: "mini"
  onClick?: () => void
  isLoading?: boolean
  innerContainerClassName?: string
  stepName?: string
  customContainerClassName?: string
}

export const Card = ({
  title,
  content,
  actionTitle,
  CTA,
  accent,
  topRightInfo,
  bottomRightInfo,
  additionalOptions,
  enableRightArrowIcon,
  leftIcon,
  rightIcon,
  size,
  onClick,
  isLoading,
  innerContainerClassName: innerContainerClassname,
  customContainerClassName,
}: Props) => {
  const [optionsEnabled, setOptionsEnabled] = useState(false)
  return (
    <div
      className={`${getContainerStyle(accent, size, !!onClick)} ${customContainerClassName || ""}`}
      onClick={onClick}
    >
      <div className={`${getInnerContainerStyle(accent)} ${innerContainerClassname}`}>
        {leftIcon && <div className={styles.icon}>{leftIcon}</div>}
        <Space direction="column" gap={0} flexGrow={1}>
          {optionsEnabled && additionalOptions && (
            <div className={styles.optionsContainer}>
              {additionalOptions.map((option) => (
                <div key={option.title} className={styles.optionContainer}>
                  {option.icon} {option.title}
                </div>
              ))}
            </div>
          )}
          {(actionTitle || topRightInfo) && (
            <div className={styles.topContent}>
              {actionTitle && (
                <LoadingWrapper isLoading={isLoading} className={styles.actionTitle}>
                  {accent === "primaryError" && <ErrorIcon />}
                  <span>{actionTitle}</span>
                </LoadingWrapper>
              )}
              {topRightInfo && <div className={styles.topRightInfo}>{topRightInfo}</div>}
            </div>
          )}

          {additionalOptions && (
            <div className={styles.optionsDotsContainer} onClick={() => setOptionsEnabled(!optionsEnabled)}>
              <ThreeDotsIcon />
            </div>
          )}
          {title && (
            <LoadingWrapper isLoading={isLoading} className={getTitleStyleFromAccent(accent)}>
              <span>{title}</span>
              {enableRightArrowIcon && (
                <div className={styles.rightArrowIcon}>
                  <RightArrowBox />
                </div>
              )}
            </LoadingWrapper>
          )}
          {content && <div className={styles.content}>{content}</div>}
          {CTA && (
            <div className={styles.CTAContainer}>
              <Button {...CTA} shape="round" type="primary" size="slim" />
            </div>
          )}
          {bottomRightInfo && <div className={styles.bottomRightInfo}>{bottomRightInfo}</div>}
        </Space>
        {rightIcon && !isLoading && <div className={styles.icon}>{rightIcon}</div>}
      </div>
    </div>
  )
}

function getContainerStyle(accent: Props["accent"], size: Props["size"], onClick: boolean) {
  let style = styles.container
  switch (accent) {
    case "primary":
      style += ` ${styles.containerAccent}`
      break
    case "primaryWhite":
      style += ` ${styles.containerAccentWhite}`
      break
    case "primaryError":
      style += ` ${styles.containerAccentError}`
      break
    case "primaryWarning":
      style += ` ${styles.containerAccentWarning}`
      break
    case "disabled":
      style += ` ${styles.containerAccentDisabled}` //TODO:
      break
    case "greyActive":
      style += ` ${styles.containerGreyActive}`
      break
    case "orangeActive":
      style += ` ${styles.containerOrangeActive}`
      break
    case "limeActive":
      style += ` ${styles.containerLime}`
      break
    default:
      break
  }
  switch (size) {
    case "mini":
      style += ` ${styles.mini}`
      break
    default:
      break
  }
  if (onClick) {
    style += ` ${styles.containerClickable}`
  }
  return style
}

function getInnerContainerStyle(accent: Props["accent"]) {
  let style = styles.innerContainer
  switch (accent) {
    case "primary":
      style += ` ${styles.containerAccent}`
      break
    case "primaryWhite":
      style += ` ${styles.containerAccentWhite}`
      break
    case "primaryError":
      style += ` ${styles.containerAccentError}`
      break
    case "primaryWarning":
      style += ` ${styles.containerAccentWarning}`
      break
    case "disabled":
      style += ` ${styles.containerAccentDisabled}` //TODO:
      break
    case "greyActive":
      style += ` ${styles.containerGreyActive}`
      break
    case "limeActive":
      style += ` ${styles.containerLime}`
      break
    case "orangeActive":
      style += ` ${styles.containerOrangeActive}`
      break
    case "darkGrey":
      style += ` ${styles.containerDarkGrey}`
      break
    case "lightGrey":
      style += ` ${styles.containerLightGrey}`
      break
    default:
      break
  }
  return style
}

function getTitleStyleFromAccent(accent: Props["accent"]) {
  switch (accent) {
    case "primary":
    case "primaryWhite":
    case "primaryError":
    case "primaryWarning":
      return `${styles.title} ${styles.titleAccent}`
    case "disabled":
      return `${styles.title} ${styles.titleDisabled}`
    default:
      return styles.title
  }
}
