import React, { Fragment } from "react"
import clsx from "clsx"
import styles from "./Banner.module.css"

interface Props {
  icon?: React.ReactNode
  title?: string
  subtitle?: string
  className?: string
}

export const Banner = ({ icon, title, subtitle, className }: Props) => {
  return (
    <Fragment>
      <div className={clsx(styles.container, className)}>
        <div className={styles.row}>
          {icon && <span className={styles.icon}>{icon}</span>}
          {title && <span className={styles.title}>{title}</span>}
        </div>
        {subtitle && (
          <div className={styles.row}>
            <span className={styles.subtitle}>{subtitle}</span>
          </div>
        )}
      </div>
    </Fragment>
  )
}
