import { formatCurrency } from "helpers/formatHelper"
import { LoadingWrapper } from "components/core/atoms"
import styles from "./DoughnutChartMiddle.module.css"

export const DoughnutChartMiddle = ({ totalAssetValue }: { totalAssetValue?: number }) => {
  return (
    <div data-cy="chart-middle-section">
      <p className={styles.topText}>Total asset value</p>
      <LoadingWrapper
        isLoading={totalAssetValue === null}
        className={styles.assetValueText}
        data-cy="chart-total-asset-value"
      >
        {totalAssetValue !== null && formatCurrency(totalAssetValue, false)}
      </LoadingWrapper>
    </div>
  )
}
